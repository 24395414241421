* {
  margin: 0;
  padding: 0;
}

aside,
section,
article,
p,
div,
span,
figure,
figcaption,
img,
input,
form,
html,
footer,
header,
nav,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

.align-items-center {
  align-items: center;
}

ul {
  list-style: none;
}

.pr-0 {
  padding-right: 0;
}

.pl-0 {
  padding-left: 0;
}

.clear {
  clear: both;
  height: 0;
}

:focus,
.btn:focus,
.btn:active:focus {
  outline: none;
}

ul:after {
  clear: both;
  display: block;
  content: "";
}

html {
  font-smooth: always;
}

img {
  max-width: 100%;
  border: none;
  vertical-align: bottom;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

button,
input[type="submit"] {
  -webkit-appearance: none;
}

body {
  background: #eeeef3;
  color: #242424;
  font-size: 16px;
  line-height: 21px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  overflow-x: hidden;
}

body,
html {
  height: 100%;
}

a {
  color: #ca8416;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 400;
}

.logo_header {
  border-bottom: 1px solid #d6d6da;
  padding: 20px 0;
}

.login_form_outer {
  max-width: 430px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}

.padding_section {
  padding: 20px 30px 0;
}

.padding_section h3 {
  font-size: 19px;
  margin-top: 1px;
  font-weight: 700;
  border-bottom: 1px solid #d3d3d352;
  padding-bottom: 13px;
}

.text_size {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  color: #13497b !important;
}

.image-uploded {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.padding_section p {
  color: grey;
}

.flex_row {
  white-space: nowrap;
}

.padding_section .main-form {
  background: white;
  border-radius: 10px;
  padding: 9px;
  margin-bottom: 10px;
}

button.approvedd {
  margin-bottom: 4px;
  background: #13497b;
  border: none;
  padding: 5px 12px;
  color: #fff;
  border-radius: 5px;
  margin-right: 2px;
}

select {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 5px;
  font-size: 15px;
  padding: 8px;
  margin-bottom: 15px;
  width: 100%;
  height: 39px;
}

.partner-type select {
  padding: 8px 70px 8px 7px !important;
}

.partner-list-section ul.nav.nav-tabs {
  justify-content: center;
  display: flex;
  border: none;
}

.upload-img img {
  width: 140px;
  border-radius: 50% 50% 50% 50%;
  height: 140px;
  object-fit: cover;
}

.Add-new-partner-btn, .add-partner-heading h2 {
  margin-top: 0 !important;
}

.top-listing-header {
  display: flex;
  align-items: center;
}

.top-listing-header select {
  max-width: 165px;
  margin: 20px 7px 0;
  cursor: pointer !important;
}

.login_form {
  background: #fff;
  border-radius: 5px;
  padding: 30px;
}

.shadow {
  vertical-align: top;
}

.login_form h3 {
  border-top: 1px solid #e5e5e5;
  color: #ca8416;
  font-weight: 400;
  margin: 0;
  font-size: 25px;
  padding-top: 20px;
}

.header_btn .dropdown-toggle {
  display: flex;
  align-items: center;
}

.login_form h2 {
  border-top: 1px solid #e5e5e5;
  color: #13497b;
  font-weight: 400;
  margin: 0;
  padding-top: 20px;
}

.login_form label {
  font-size: 18px;
  color: #b5bbcb;
  font-weight: 400;
}

.login_form .form-control {
  box-shadow: none;
  height: auto;
  padding: 8px 15px;
}

.forgot_password a {
  color: #f1a732;
  text-decoration: underline;
  font-size: 14px;
}

.forgot_password a:hover {
  text-decoration: none;
}

.login_form .form-group {
  margin-top: 20px;
  margin-bottom: 0;
}

.blue_btn {
  background: #13497b;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  border: none;
  padding: 7px 30px;
  display: inline-block;
  line-height: 26px;
  margin-top: 20px;
  font-weight: 700;
  margin-left: 10px;
}

.blue_btn:hover {
  background: #13497b;
}

.login_form h2+p {
  margin-top: 20px;
}

/*** dashboard page css here ***/
.header {
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  height: 78px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  -webkit-transform: translateZ(0);
  z-index: 10;
  min-width: 700px;
}

.dashboard_logoarea {
  background: #fff;
  float: left;
  width: 270px;
  height: 78px;
  padding: 13px 0px;
}

.dashboard_logoarea img {
  height: 60px;
}

.right_loggedarea {
  float: right;
  margin-right: 15px;
  margin-top: 18px;
}

.right_loggedarea ul li {
  display: inline-block;
  margin-left: 25px;
  font-size: 14px;
  vertical-align: middle;
}

.right_loggedarea ul li a {
  color: #b5bbcb;
}

.right_loggedarea ul li a i,
.right_loggedarea ul li a:hover {
  color: #999999;
}

.notification_count {
  display: inline-block;
  min-width: 22px;
  min-height: 22px;
  background: #f1a732;
  text-align: center;
  color: #fff;
  font-size: 12px;
  border-radius: 100%;
  margin-left: 5px;
}

.right_loggedarea ul li img {
  vertical-align: middle;
  margin-right: 10px;
}

.right_loggedarea ul li a i {
  vertical-align: text-top;
}

.main-page {
  padding-top: 78px;
  padding-left: 270px;
  height: 100%;
  min-width: 700px;
}

.left_sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background: #13497b;
  width: 270px;
  padding-top: 85px;
  z-index: 1;
  overflow: auto;
  -webkit-transform: translateZ(0);
  transition: 0.3s all ease-in;
  -moz-transition: 0.3s all ease-in;
  -webkit-transition: 0.3s all ease-in;
  -ms-transition: 0.3s all ease-in;
  -o-transition: 0.3s all ease-in;
}

.left_sidebar ul li {
  border-bottom: 1px solid #57699d;
}

.left_sidebar ul li:last-child {
  border-bottom: none;
}

.left_sidebar ul li a {
  color: #fff;
  display: block;
  padding: 12px 5px 12px 22px;
}

.left_sidebar ul li a i {
  min-width: 30px;
}

.left_sidebar ul li.active a,
.left_sidebar ul li:hover a {
  color: #fff;
  cursor: pointer;
  border-left: 4px solid white;
}

.btn-primary {
  color: #fff;
  background-color: #13497b !important;
  border-color: #13497b !important;
}

.left_sidebar ul li.active a span,
.left_sidebar ul li:hover a span {
  text-decoration: underline;
}

input.rc-time-picker-input {
  border: none;
  position: relative;
  top: -8px;
}

.content_area {
  padding: 35px 15px 5px 15px;
  position: relative;
  min-height: 750px;
  /* height: 100vh; */
  /* padding-left: 280px; */
}

.userpropertylisting-bck-btn {
  margin: 25px 0 0 15px;
}

.searchbtn_btn {
  position: relative;
  margin-top: 20px;
}

.filter_btn {
  position: relative;
}

.clndr1 .form-control input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

.clndrinput {
  position: relative;
}

.clndrinput span {
  position: absolute;
  top: 50%;
  right: 10px;
  color: #e68f06;
  pointer-events: none;
  transform: translate(0, -50%);
}

.clndr1 input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.searchbtn_btn input {
  background-color: white;
  border: 1px solid #13497b;
  padding: 9px 11px 12px;
  border-radius: 5px;
  padding-right: 27px;
  height: 39px;
  font-size: 13px;
  min-width: 217px;
}

.filter_btn input {
  background-color: white;
  border: 1px solid #13497b;
  padding: 9px 11px 12px;
  border-radius: 5px;
  padding-right: 27px;
  height: 39px;
  font-size: 13px;
  min-width: 217px;
}

.searchbtn_btn span {
  position: absolute;
  right: 0;
  top: 46%;
  cursor: pointer;
  color: #13497b;
  transform: translate(-50%, -50%);
}

.filter_btn span {
  position: absolute;
  right: 0;
  top: 46%;
  cursor: pointer;
  color: #13497b;
  transform: translate(-50%, -50%);
}

.searchbtn_btn input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 12px;
}

.filter_btn input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 12px;
}

.searchbtn_btn input::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
}

.auction-type select {
  margin-bottom: 0 !important;
}

input[type="date"] {
  -webkit-appearance: none !important;
}

.image-box-new {
  min-width: 60px;
  height: 60px;
  width: 60px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 50%;
}

button.back-btn {
  float: right;
  margin-top: -48px;
  background: #144b79;
  color: #fff;
  padding: 5px 20px;
  border-radius: 4px;
  border: none;
}

button.back-btn.edit-property-back-btn {
  float: left !important;
  margin-top: 0 !important;
}

.image-modal {
  background: #000000cf;
}

.cross-bt {
  font-size: 30px;
  color: #fff;
  position: absolute;
  right: -30px;
  top: -30px;
  cursor: pointer;
}

.image-modal .modal-dialog {
  width: 700px;
  /* margin: 30px auto; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.modal-img {
  height: 500px;
  object-fit: cover;
  width: 100%;
}

.react-date-picker__wrapper {
  background: white;
  height: 38px;
  border-radius: 5px;
  margin-right: 6px;
  border-color: #d7d7d7 !important;
}

a.item_text {
  color: #144b79;
  cursor: pointer;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
	display: none;
  } */

.filter_btn input::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
}

.time-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ms-2 {
  margin-left: 5px;
}

.pull-right.filter_btn {
  margin-right: 5px;
}

.searchbtn_btn input:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 12px;
}

.filter_btn input:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 12px;
}

.searchbtn_btn input:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
}

.filter_btn input:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
}

.top3_a rea {
  padding: 10px 0 0;
}

.white_three {
  background: #fff;
  margin: 0px 0 24px 29px;
  text-align: center;
  padding: 25px;
  position: relative;
  border-radius: 2px;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.radio_btn {
  display: flex;
  margin-bottom: 0 !important;
}

.radio_btn label {
  margin-left: 5px;
  margin-bottom: 0px;
}

.form-group.radio_btn input {
  font-size: 16px;
  color: #242424;
  height: 17px;
  box-shadow: none;
  padding: 8px 12px;
  display: inline-block;
  position: unset;
  width: 17px;
}

.swal2-actions button {
  border: 0 !important;
  box-shadow: none !important;
}

button:focus {
  outline: 0;
  border: 0;
}

.white_three p {
  color: #b5bbcb;
}

.swal2-close {
  padding: 20px !important;
}

.swal2-close:focus {
  border: 0 !important;
  box-shadow: none !important;
}

.white_three span {
  position: absolute;
  left: -29px;
  width: 58px;
  height: 58px;
  font-size: 30px;
  color: #fff;
  line-height: 55px;
  border-radius: 2px;
  top: 15px;
}

.white_three h3 {
  margin-top: 0;
}

.white_box {
  background: #fff;
  padding: 15px;
  border-radius: 2px;
}

.total_sold h4 {
  font-weight: 700;
  color: #333333;
  margin-bottom: 7px;
  margin-top: 0;
}

.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 15px;
}

.right_loggedarea .dropdown-menu {
  background: #13497b;
  right: 0;
  left: inherit;
  top: 58px;
}

.right_loggedarea .dropdown-menu a {
  display: block;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  border-bottom: 1px solid #6b8498;
}

.right_loggedarea .dropdown-menu a:last-child {
  border-bottom: none;
}

.right_loggedarea .dropdown-menu a i {
  color: #fff;
  vertical-align: middle;
  min-width: 25px;
}

.right_loggedarea .dropdown-menu a:hover,
.right_loggedarea .dropdown-menu a:hover i {
  color: #fff;
}

.right_loggedarea .dropdown-menu::after {
  position: absolute;
  content: "";
  border-top: 10px solid transparent;
  border-bottom: 10px solid #13497b;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  top: -20px;
  right: 5px;
}

.yellow_btn {
  font-size: 16px;
  font-weight: 700;
  background: #f1a732;
  padding: 6px 20px;
  margin-left: 10px;
}

.yellow_btn:hover {
  background: #ca8416;
}

.tables_area .white_box {
  padding: 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  margin-top: 15px;
}

.table-responsive table thead tr th {
  color: #13497b;
  font-size: 14px;
  font-weight: 700;
  border-bottom: 2px solid #13497b;
  text-transform: uppercase;
  padding: 10px;
}

.table-responsive table tbody tr td {
  color: #242424;
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 12px 10px;
}

.table-responsive table tbody tr td:last-child {
  border-right: none;
}

.table-responsive table tbody tr:nth-child(2n) td {
  background: #eeeef3;
}

.action_btn {
  font-size: 18px;
  display: inline-flex;
  margin: 0 5px;
  width: 28px;
  height: 28px;
  background-color: #dd8700;
  border-radius: 100px;
  color: white;
  justify-content: center;
  align-content: center;
  padding: 4px;
  margin: 2px 2px;
}

.action_btn:hover {
  color: #fff;
}

.main-page.signpage {
  padding-left: 0 !important;
}

.login_form_outer .blue_btn {
  width: 100%;
  text-align: center;
  padding: 10px;
  margin-top: 30px;
}

.logo_row {
  text-align: center;
  width: 100%;
  display: block;
  margin: -4px 1px 27px 0;
}

/*** dashboard page css here ***/

/*** add new fleet page css here ***/
.add_formarea {
  padding: 15px;
}

.big_yellow {
  font-size: 18px;
  padding: 10px 35px;
  margin-bottom: 20px;
}

.add_formarea label {
  font-size: 14px;
  color: #242424;
  font-weight: 400;
  letter-spacing: 1px;
}

.add_formarea .form-control {
  font-size: 16px;
  color: #242424;
  height: auto;
  box-shadow: none;
  padding: 8px 12px;
}

.add_map a {
  font-size: 14px;
  text-decoration: underline;
}

.icon_input {
  position: relative;
}

.icon_input i {
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.form-group h3 {
  margin-top: 5px;
}

.custom_select select {
  width: 100%;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  line-height: 40px;
}

.checkinput input {
  width: 21px;
  height: 21px;
}

.custom_select {
  position: relative;
}

.checkrow {
  position: relative;
  /* display: inline-block; */
}

.checkrow .checkinput {
  position: absolute;
  left: 0;
}

.custom_select::after {
  position: absolute;
  top: 1px;
  width: 40px;
  background: #fff;
  bottom: 1px;
  text-align: center;
  content: "\f0dd";
  font-family: fontawesome;
  right: 1px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 20px;
  line-height: 30px;
  pointer-events: none;
}

.tags li {
  display: inline-block;
  margin-top: 12px;
}

.tags li span {
  display: inline-block;
  border-radius: 5px;
  background: #ca8416;
  font-size: 14px;
  position: relative;
  padding-right: 20px;
  color: #fff;
  padding: 3px 25px 3px 8px;
}

.tooltip {
  font-size: 13px;
}

.paginationrow .pagination>.active>a {
  background-color: #13497b;
  color: white;
  border-color: #13497b;
}

.paginationrow .pagination>li>a {
  color: #13497b;
}

.tags li span a {
  position: absolute;
  right: 7px;
  top: 3px;
  color: #fff;
}

.top_form {
  padding-bottom: 20px;
}

.top_form .yellow_btn {
  height: 39px;
  margin: 0;
  vertical-align: middle;
  padding: 6px 35px;
  font-size: 18px;
}

.hide-btn:after {
  content: "";
  position: absolute;
  background: #eee;
  top: 32px;
  right: 12px;
  width: 30px;
  height: 30px;
}

/*** add new fleet page css here ***/

/*** accepted payment page css here ***/
.payment_whitebox {
  background: #fff;
  padding: 12px 25px;
  border-radius: 3px;
  border: 2px solid #fff;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.12);
  margin-bottom: 15px;
}

.payment_options li {
  float: left;
  width: 210px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  margin-right: 20px;
  font-size: 14px;
}

.payment_options li:last-child {
  margin-right: 0;
}

.payment_options li a {
  color: #242424;
}

.payment_options li a.active {
  color: #ca8416;
}

.payment_options li a.active .payment_whitebox {
  border-color: #ca8416;
}

.payment_options {
  padding-bottom: 20px;
}

/*** accepted payment page css here ***/

/*** my Profile page css here ***/
.profile_image {
  border: 2px solid #c2c2c2;
  margin: 20px 0;
  width: 200px;
  height: 200px;
}

.my_profile label {
  font-size: 14px;
  color: #b5bbcb;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
}

.my_profile h4 {
  font-size: 20px;
  margin-top: 0px;
}

.my_profile .col-sm-8 .row {
  margin-top: 15px;
}

.upload_text {
  color: #c4c4c5;
  font-size: 14px;
}

.upload_image {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.upload_image input {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.my_profile .form-control {
  height: auto;
  padding: 10px;
  color: #000;
  box-shadow: none;
  margin-bottom: 20px;
}

.yellow_btn,
.blue_btn {
  cursor: pointer;
}

.my_profile .yellow_btn {
  font-size: 16px;
  padding: 10px 14px;
  margin: 25px 0;
}

.inner_chnage_password {
  margin-top: 40px;
}

.inner_chnage_password .login_form_outer {
  max-width: 430px;
  position: unset;
  top: 50%;
  left: 50%;
  margin: auto;
  transform: unset;
  width: unset;
}

.donebtn[disabled] {
  background-color: grey;
}

.upload-profile.video_box {
  width: 155px !important;
  /* height: 100% !important; */
}

.upload-profile.video_box video {
  width: 100% !important;
  /* height: auto !important; */
}

.ReactModal__Content {
  position: unset !important;
  display: inline-block;
  /* max-width: 500px !important;  */
  max-height: 570px;
  margin: auto;
  /* margin-top: 50px; */
}

.ReactCrop {
  /* height: 300px !important; */
}

.btnrow1 {
  display: flex;
  justify-content: center;
  margin: 0px 0 10px;
  display: block;
  text-align: right;
  align-items: center;
  position: sticky;
  z-index: 1025 !important;
  top: 0px;
  /* left :50%;
	transform: translate(-50%, -50%); */
}

.ReactModal__Content.ReactModal__Content--after-open {
  /* max-width: 50%; */
  z-index: 1025 !important;
  left: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  padding-top: 50px !important;
}

.ReactModal__Overlay--after-open {
  background-color: rgb(41 41 41 / 75%) !important;
  z-index: 1025 !important;
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0;
}

.donebtn {
  background-color: #f1a731;
  color: white !important;
  width: 100px;
  padding: 7px 10px;
}

.clsebtn {
  background-color: #ff0000;
  color: white !important;
  padding: 7px 10px;
  width: 45%;
}

.modal {
  opacity: 1 !important;
}

.img_box.video_box1 a.action_btn {
  top: -13px;
  right: -10px;
}

.main_outer_box .action_btn {
  background-color: transparent;
}

.dltebtn1 {
  background: #f63802;
  color: white;
  padding: 4px 10px;
  border-radius: 3px;
  margin-bottom: 16px;
  display: inline-block;
  font-size: 14px;
}

.addbtn1 {
  background: green;
  color: white;
  padding: 4px 10px;
  border-radius: 3px;
  margin-bottom: 16px;
  display: inline-block;
  font-size: 14px;
}

.top_form .form-control,
.search_input .form-group .form-control {
  height: auto;
  padding: 8px 10px;
  box-shadow: none;
}

/*** my Profile page css here ***/

/*** payment transaction page css here ***/
.top_form h4 {
  margin-top: 30px;
}

.search_form li {
  float: left;
  width: 19%;
  margin-right: 1.2%;
}

.search_form li:last-child {
  margin-right: 0;
}

/*** payment transaction page css here ***/

/*** subscription page css here ***/
.subscription_box {
  background: #fff;
  padding: 12px;
}

.subscription_box h1 {
  font-weight: 700;
  color: #ca8416;
  font-size: 62px;
}

.subscription_time {
  position: relative;
  color: #f1a732;
  text-transform: uppercase;
}

.subscription_time:after {
  position: absolute;
  left: 0;
  top: 20px;
  content: "";
  width: 100%;
  height: 1px;
  background: #f1a732;
}

.subscription_time span {
  display: inline-block;
  background: #fff;
  padding: 10px;
  position: relative;
  z-index: 1;
}

.subscription_box p {
  color: #b5bbcb;
  font-size: 18px;
  margin-top: 25px;
}

.subscription_box h3 {
  margin-top: 10px;
}

/*** subscription page css here ***/

/*** booking page css here ***/
.search_input .form-group {
  margin: 15px 0 0 0;
}

.search_input {
  min-width: 260px;
}

.ticket_detailbox p,
.ticket_detailbox h5 {
  color: #b5bbcb;
  font-size: 16px;
}

.ticket_detailbox p span {
  color: #000;
}

.ticket_detailbox .yellow_btn {
  font-size: 18px;
}

.ticket_detailbox h5 span {
  margin-left: 15px;
}

.ticket_detailbox h5 {
  margin-top: 30px;
}

.onward_returntable table thead tr th {
  border-color: #b5bbcb;
}

.onward_returntable table tbody tr td {
  vertical-align: top;
  border-right: none;
}

.onward_returntable table tbody tr:last-child td {
  border-bottom: none;
}

.onward_returntable table tbody tr td h4 {
  font-weight: 700;
  margin: 0;
  color: #242424;
}

.ticket_detailbox td {
  background: #fff !important;
}

.ticket_detailbox>td {
  padding: 0px 25px 25px !important;
}

.hide_detail {
  display: inline-block;
  margin: 0 10px;
}

.dashboard_logoarea a {
  display: inline-block;
  vertical-align: middle;
}

.dashboard_logoarea a.menu_bar {
  display: none;
  vertical-align: middle;
  font-size: 28px;
  margin-right: 15px;
}

/*** booking page css here ***/

/*** date picker css here ***/
.ui-datepicker.ui-widget {
  background: #fff;
  border: 1px solid #ddd;
  min-width: 311px;
}

.ui-datepicker.ui-widget .ui-datepicker-header {
  background: none;
  border: none;
}

.ui-datepicker.ui-widget .ui-datepicker-prev .ui-icon,
.ui-datepicker.ui-widget .ui-datepicker-next .ui-icon {
  background: url("../images/left_arrow.png") no-repeat scroll center center;
  cursor: pointer;
  height: 35px;
  left: 0;
  margin: 0;
  top: 0;
  width: 35px;
}

.ui-datepicker.ui-widget .ui-datepicker-next .ui-icon {
  background: url("../images/right_arrow.png") no-repeat scroll center center;
}

.ui-datepicker-title {
  color: #000;
}

.ui-datepicker.ui-widget a.ui-state-active {
  background: #15a5fb;
  color: #fff;
}

.ui-datepicker.ui-widget .ui-state-default,
.ui-datepicker.ui-widget .ui-widget-content .ui-state-default,
.ui-datepicker.ui-widget .ui-widget-header .ui-state-default {
  color: #000;
  padding: 0 10px;
  text-align: center;
  text-shadow: none;
}

.ui-datepicker .ui-datepicker-prev {
  left: 2px !important;
  top: 2px !important;
}

.ui-datepicker .ui-datepicker-next {
  right: 2px !important;
  top: 2px !important;
}

.bootstrap-timepicker-widget.dropdown-menu.open {
  display: block;
  padding: 5px;
}

.bootstrap-timepicker-widget {
  text-align: center;
}

.bootstrap-timepicker-widget input {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  width: 75px;
}

.bootstrap-timepicker-widget table td {
  padding: 5px 10px;
}

/*** date picker css here ***/

.custom_select .fstElement {
  display: block;
  font-size: 12px;
  border-radius: 5px;
}

.custom_select .fstElement .fstControls {
  width: 100%;
}

.custom_select .fstElement .fstControls .fstQueryInput {
  color: #000;
  padding: 5px;
}

.custom_select .fstChoiceItem,
.custom_select .fstResultItem.fstSelected,
.custom_select .fstResultItem.fstFocused {
  background-color: #ca8416;
  border-color: #ca8416;
}

.attireMainNav {
  display: none;
}

.add_formarea h4 {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px;
  color: #ca8416;
}

.online_user h4 {
  font-weight: 700;
  color: #333333;
  margin-bottom: 35px;
}

.online_user h4 a {
  color: #f1a732;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  float: right;
}

.online_user h4 a:hover {
  text-decoration: none;
}

.online_user ul li {
  position: relative;
  padding-left: 80px;
  border-bottom: 1px solid #dddddd;
  min-height: 98px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.online_user ul li:last-child {
  border-bottom: none;
}

.online_user ul li img {
  border-radius: 100%;
  border: 2px solid #cdcdcd;
  position: absolute;
  left: 0;
  top: 15px;
  width: 68px;
  height: 68px;
}

.online_user_status {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #00ff0c;
  position: absolute;
  display: inline-block;
  right: 0;
  top: 30px;
}

.online_user ul li p {
  padding-top: 23px;
}

.current_commission {
  background: #d7e7db;
  font-size: 18px;
  color: #242424;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 45px;
}

.plan_action_btn {
  color: #242424;
  display: inline-block;
  margin-top: 10px;
  margin-right: 20px;
}

.plan_action_btn:hover {
  color: #ca8416;
}

/* .upload-profile {
    width: 100px;
    height: 100px;
    background: #e6e6e6;
    border-radius: 100px;
    position: relative;
	margin: 20px 500px;
}
.upload-input {
    width: 100%;
    min-height: 100px;
    position: absolute;
    opacity: 0;
    border-radius: 100px;
    background: #000;
}
.upload-profile img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    object-fit: cover;
	overflow: hidden;
}*/
.upload-icon {
  position: absolute;
  top: 40px;
  left: 36px;
  font-size: 23px;
  color: #f1a732;
}

.upload-profile {
  background-color: #cdcdce;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

.upload-profile img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: auto;
}

.upload-profile input {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-bottom: 20px;
  opacity: 0;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100% !important;
  cursor: pointer;
  z-index: 1024;
  margin-bottom: 0 !important;
}

.uploaded-pictures {
  display: flex;
  align-items: center;
}

.picture-one-new {
  margin: 20px 20px 20px 0px;
}

.login-form {
  background: white;
  border-radius: 15px;
  padding: 20px;
  margin-top: 15px;
}

.d-flex input {
  margin-right: 5px;
}

.listing-submit {
  background: #144b79;
  color: #fff !important;
  font-size: 17px;
  width: 120px;
  margin-top: 5px !important;
}

.form-check.form-check-inline input {
  margin-right: 5px;
}

.form-check.form-check-inline {
  margin-right: 25px;
}

.main-bath-check {
  display: flex;
  flex-wrap: wrap;
}

.upload-img input {
  width: 140px;
  height: 140px;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0;
  cursor: pointer !important;
}

.upload-img {
  position: relative;
}

.listing-tab-top.nav.nav-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border: none !important;
}

.login-form li.nav-item button {
  width: 200px;
  background: white;
  height: 41px;
  border-radius: 5px;
}

.login-form li.nav-item button.active {
  background: #144b79;
  color: #fff;
}

.d-flex {
  display: flex;
}

.modal-body {
  position: relative;
  padding: 15px;
  margin-top: 200px;
}

.modal .modal-content {
  border: 0;
  border-radius: 5px;
}

.btn.okbtn {
  background: #11dfed;
  box-shadow: 0px 4px 14px 5px rgba(17, 223, 237, 0.1);
  border-radius: 5px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  padding: 13px 35px;
}

.btn.okbtn:hover {
  background-color: #0bcfdd;
}

.otp_popup_verify h5 {
  font-weight: 600;
  font-size: 18px;
  color: #1d1d1d;
}

.form-control {
  margin-top: 0;
}

.form-control {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 5px;
  font-size: 15px;
  padding: 13px;
}

.my-3 {
  margin-top: 15px;
}

.my_property_btn {
  background: #fff;
  border-radius: 5px;
  color: #000;
  font-size: 16px;
  border: none;
  padding: 7px 30px;
  display: inline-block;
  line-height: 26px;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 700;
}

.form-box {
  margin-top: 19px;
}

.add-text {
  margin-top: 12px;
}

.loader-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  width: 100%;
  background: #040404e0 !important;
  position: absolute;
  z-index: 1024;
  top: 50%;
}

.forgot_section {
  height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 0 !important;
  justify-content: center;
}

.form-check.form-check-inline label {
  font-size: 16px;
  font-weight: 400;
}

.uploaded-pictures img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
  border: 1px solid #f5f5f5;
}

.uploaded-pictures i {
  position: absolute;
  background: red;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  font-size: 14px;
  color: #fff;
  padding: 0 4px;
  right: -5px;
  top: -5px;
  cursor: pointer;
}

.forgot_section .btn {
  background: #ca8416;
  width: 100%;
  padding: 10px;
  color: white;
  margin-top: 13px;
}

.main-loader {
  background: #1a1a1ac9 !important;
  width: 100%;
  height: 100vh;
  z-index: 1;
  position: fixed;
  z-index: 1206;
  top: 0;
  left: 0;
}

.img_box {
  position: relative;
  margin: 10px 14px 0 0;
}

.img_box .upload-profile {
  width: 120px;
  height: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #eeeef3;
}

.img_box .upload-profile img,
.img_box .upload-profile video {
  height: 100%;
}

.img_box a.action_btn {
  position: absolute;
  right: 0;
}

.required:after {
  content: " *";
  color: #d50000;
}

.main_outer_box {
  display: flex;
  flex-wrap: wrap;
}

.img_box a.action_btn i {
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  background: #ca8416;
  border-radius: 100px;
  color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  line-height: 1.6;
  border: 2px solid white;
  font-size: 15px;
}

.upload_btn1 {
  position: relative;
  width: 201px;
}

.upload_btn1 .upload-profile {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.upload_btn1 .add-text {
  background: #ca8416;
  color: white;
  padding: 9px;
}

.img_box .upload-profile.video_box {
  border-radius: 9px;
}

.img_box a.action_btn .action_btn_video {
  top: 5px;
}

.add-partner-heading-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.desc-modal-data .modal-body {
  margin-top: 0 !important;
}

.desc-modal-data .modal-dialog.modal-lg.modal-dialog-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.desc-modal-data .modal-title img {
  width: 13px;
  position: absolute;
  right: 15px;
  top: 18px;
  cursor: pointer;
}

.desc-modal-data .modal-body p {
  line-height: 26px;
  max-height: 300px;
  overflow: auto;
  text-align: justify;
  padding-right: 20px;
}

.desc-modal-data .modal-body p {
  line-height: 26px;
}

.desc-modal-data .modal-title h4 {
  margin: 0;
  color: #1d4a7a;
  font-size: 19px;
  font-weight: 600;
}

/*** responsive css here ***/
@media screen and (max-width: 1200px) {
  .search_form li {
    width: 32%;
    margin-right: 1.333%;
  }

  .online_user,
  .subscription_box {
    margin-top: 15px;
  }
}

@media screen and (max-width: 991px) {
  .main-page {
    padding-left: 0;
  }

  .left_sidebar {
    left: -270px;
  }

  .left_sidebar.active {
    left: 0;
  }

  .dashboard_logoarea a.menu_bar {
    display: inline-block;
  }
}

@media screen and (max-width: 767px) {
  .login_form_outer {
    margin: 20px auto 0;
  }
}

/* @media (max-width:600px){
	.ReactModal__Content{
		width: 100%;
		max-width: unset !important;
	}
} */

/*** responsive css here ***/